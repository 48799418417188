import i18next, { t } from "i18next";
import PageLayout from "../../components/pageLayout/pageLayout";
import { useEffect, useState } from "react";
import { IUser, IUserMetadata } from "../../interfaces/user";
import { AuthService } from "../../services/authService";
import { Form, Radio, Input, Button, Select, ConfigProvider, Divider, Alert, Modal, Space } from "antd";
import { Constants } from "../../constants";
import { PlusOutlined } from '@ant-design/icons';
import itIT from "antd/locale/it_IT";
import { UtilsService } from "../../services/utilsService";
import Logo from "../../components/logo/logo";
import StripeLogo from "../../assets/images/powered-by-stripe.svg";
import "./settingsPage.css";
import { useTranslation } from 'react-i18next'
import PlanCard from "../../components/planCard/planCard";
import { IPlan } from "../../interfaces/plan";
import { PaymentService } from "../../services/paymentService";
import { useSearchParams } from "react-router-dom";
import PaymentSuccessLottie from "../../assets/json/payment-success.json";
import Lottie from "lottie-react";
import { AppRoutes } from "../../routes/routes";

const imageMaxSizeMb = 10;

export const locales: { [key: string]: any } = {
    it: itIT,
    en: null,
};

export interface IUpdateUserForm {
    name: string;
    surname: string;
    lang: string;
    school_type: string;
    avatar_url: string;
}

const layout = {
    labelCol: { span: 4, },
    wrapperCol: { span: 7 },
};

const tailLayout = {
    wrapperCol: { offset: 0, span: 4 },
};

const SettingsPage = () => {
    const user: IUser = AuthService.getUser();
    const [profileImage, setProfileImage] = useState<string>('');
    const [profileImageFile, setProfileImageFile] = useState();
    const splittedName = user?.user_metadata.full_name.split(' ');
    const { t, i18n } = useTranslation()
    const [saving, setSaving] = useState<boolean>(false);
    const [form] = Form.useForm();
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [plans, setPlans] = useState<IPlan[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [successPaymentModalVisible, setSuccessPaymentModalVisible] = useState<boolean>(false);
    const [availableSimulations, setAvailableSimulations] = useState<number>(0);

    function loadTitle() {
        document.title = `PassMate | ${t('pages.dashboard.settings.title')}`;
    }

    async function loadPlans() {
        setPlans(await PaymentService.getPlans());
        await PaymentService.updateUserPlan();
    }

    async function checkPaymentSession() {
        if (searchParams.get('session_id')) {
            setSuccessPaymentModalVisible(true);
            window.history.replaceState(null, '', `/${AppRoutes.SETTINGS}`);
        }
        if (searchParams.get('failed_checkout')) {
            UtilsService.showErrorToast(t('pages.dashboard.settings.checkout_error'));
            window.history.replaceState(null, '', `/${AppRoutes.SETTINGS}`);
        }
        await PaymentService.updateUserPlan();
        setAvailableSimulations(await PaymentService.getAvailableSimulationsNumber());
    }

    useEffect(() => {
        checkPaymentSession();
        setProfileImage(user.user_metadata.avatar_url);
        loadTitle();
        loadPlans();
    }, []);


    const onFinish = async (values: IUpdateUserForm) => {
        try {
            setSaving(true);

            let profileImageUrl = user.user_metadata.avatar_url;

            // Upload profile image
            if (profileImageFile) {
                const uploadResponse = await AuthService.uploadAvatarImage(profileImageFile);
                profileImageUrl = `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/avatars/${uploadResponse.path}`;
            }

            const body: IUserMetadata = {
                full_name: values.name + ' ' + values.surname,
                lang: values.lang,
                avatar_url: profileImageUrl,
                school_type: values.school_type,
            };

            await AuthService.updateUserInfo(body);
            i18n.changeLanguage(values.lang);
            UtilsService.showSuccessToast(t('pages.dashboard.settings.save_success'));
            loadTitle();
        } catch (e) { }
        setSaving(false);
        setIsDirty(false);
    }

    const onChange = () => {
        setIsDirty(true);
    }

    function triggerFileUpload() {
        document.getElementById('fileUploadInput')?.click();
    }

    function onFileSelect(e: any) {
        const files = e.target.files;
        if (files.length > 0) {
            const file = files[0];
            // Check file type
            if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
                UtilsService.showErrorToast(t('pages.dashboard.settings.image_type_error'));
                return;
            }

            // Check max size
            const fileSizeMbs = file.size / 1024 / 1024;
            if (fileSizeMbs > imageMaxSizeMb) {
                UtilsService.showErrorToast(t('pages.dashboard.settings.image_size_error', { max: imageMaxSizeMb }));
                return;
            }

            // Preview
            setProfileImageFile(file);
            setProfileImage(URL.createObjectURL(file));
        }
    }

    return <PageLayout
        previousRoute="/"
        title={t('pages.dashboard.settings.title')}
        subtitle={t('pages.dashboard.settings.subtitle')}
        header={<div className="header-logo"><Logo /></div>}
        body={
            <div>
                <div className="settings-card">
                    <ConfigProvider locale={locales[i18next.language]}>
                        <Form
                            form={form}
                            size="large"
                            onFinish={onFinish}
                            onChange={onChange}
                            {...layout}
                        >
                            <Form.Item name="avatar_url" label={t('pages.dashboard.settings.profile_image')}>
                                <div>
                                    <div className="avatar-container" onClick={triggerFileUpload}>
                                        {(profileImage && profileImage !== '') && <img src={profileImage} />}
                                        {(!profileImage || profileImage === '') && <PlusOutlined />}
                                    </div>
                                    <input type="file" className="file-upload" id="fileUploadInput" onChange={onFileSelect} />
                                </div>
                            </Form.Item>
                            <Form.Item label={t('pages.dashboard.settings.name')} name="name" initialValue={splittedName.length > 2 ? splittedName[0] + ' ' + splittedName[1] : splittedName[0]} rules={[{ required: true }]}>
                                <Input placeholder={t('pages.dashboard.settings.name_placeholder')} />
                            </Form.Item>
                            <Form.Item label={t('pages.dashboard.settings.surname')} name="surname" initialValue={splittedName.length > 2 ? splittedName[2] : splittedName[1]} rules={[{ required: true }]}>
                                <Input placeholder={t('pages.dashboard.settings.surname_placeholder')} />
                            </Form.Item>
                            <Form.Item label={t('pages.dashboard.settings.language')} name="lang" initialValue={user?.user_metadata.lang} rules={[{ required: true }]}>
                                <Select
                                    onChange={onChange}
                                    options={Constants.AVAILABLE_LANGUAGES.map((code) => (
                                        {
                                            value: code,
                                            label: t('generics.languages.' + code)
                                        }
                                    ))}
                                />
                            </Form.Item>
                            <Form.Item label={t('pages.dashboard.settings.educational_level')} name="school_type" initialValue={user?.user_metadata.school_type} rules={[{ required: true }]}>
                                <Select
                                    onChange={onChange}
                                    options={Constants.AVAILABLE_SCHOOL_TYPES.map((code) => (
                                        {
                                            value: code,
                                            label: t('generics.school_types.' + code)
                                        }
                                    ))}
                                />
                            </Form.Item>
                            <Divider />
                            <Form.Item {...tailLayout}>
                                <Button disabled={!isDirty} type="primary" htmlType="submit" className="submit-button" loading={saving}>
                                    {t('pages.dashboard.settings.save')}
                                </Button>
                            </Form.Item>
                        </Form>
                    </ConfigProvider>
                </div>
                <Alert message={t('pages.dashboard.settings.available_simulations') + Math.floor(availableSimulations)} showIcon className="available-simulations" />
                <div className="plans-box">
                    {plans.map((plan) => (
                        <PlanCard {...plan} key={plan.id} />
                    ))}
                </div>
                <div className="payment-problems-container">
                    <img src={StripeLogo}></img>
                    <p className="text-sm">{t('pages.dashboard.settings.payment_problems')} <a href="mailto:info@passmate.app">{t('pages.dashboard.settings.contact_now')}</a></p>
                </div>
                <Modal open={successPaymentModalVisible} footer={null} onCancel={() => setSuccessPaymentModalVisible(false)} centered>
                    <div className="modal-success-payment-content">
                        <Lottie animationData={PaymentSuccessLottie} style={{ width: "50%" }} />
                        <h3>{t('pages.dashboard.settings.payment_success_title')}</h3>
                        <p className="text">{t('pages.dashboard.settings.payment_success_description')}</p>
                    </div>
                </Modal>
            </div>
        }
    ></PageLayout>
};

export default SettingsPage;