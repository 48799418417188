import { Avatar, Button, ConfigProvider, Drawer, Dropdown, MenuProps } from "antd";
import "./pageLayout.css";
import { Constants } from "../../constants";
import { ClipLoader } from "react-spinners";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { DownOutlined, MenuOutlined, UserOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { AuthService } from "../../services/authService";
import { AppRoutes } from "../../routes/routes";
import { useState } from "react";

function PageLayout(props: any) {
  const user = AuthService.getUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [showMobileSidebar, setShowMobileSidebar] = useState<boolean>(false);

  const dashBoard = location.pathname === "/";

  function goBack(): void {
    navigate(props.previousRoute);
  }

  async function onSettingsMenuClick(event: any) {
    switch (event.key) {
      case "1":
        navigate(`/${AppRoutes.SETTINGS}`);
        break;
      default:
        await AuthService.logout();
        window.location.replace(AppRoutes.SIGN_IN);
    }
  }

  function toggleMobileSidebar() {
    setShowMobileSidebar(!showMobileSidebar);
  }

  const items: MenuProps["items"] = [
    {
      label: t("common.settings"),
      key: "1",
    },
    {
      type: "divider",
    },
    {
      label: t("common.logout"),
      key: "2",
      danger: true,
    },
  ];

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: Constants.COLOR_PRIMARY,
        },
      }}
    >
      <Drawer onClose={toggleMobileSidebar} open={showMobileSidebar}>
        <div className="sidebar-header">
          <div>
            <h3>{props.sidebarTitle}</h3>
            <h5 className="sidebar-subtitle">{props.sidebarSubtitle}</h5>
          </div>
          <div>{props.sidebarAction}</div>
        </div>
        <div className="sidebar-body">{props.sidebarBody}</div>
      </Drawer>
      <div className="mainContainer">
        <div className="container">
          <div className="content">
            <div className="content-navbar">
              <div className={dashBoard ? "header-left" : "header-custom-left"}>
                {props.previousRoute && (
                  <Button
                    type="text"
                    shape="circle"
                    icon={<ArrowLeftOutlined />}
                    onClick={goBack}
                  />
                )}
                <div
                  className="header-titles"
                >
                  {!props.previousRoute && (
                    <div className="header-titles">
                      <Dropdown menu={{ items, onClick: onSettingsMenuClick }}>
                        <span className="settings-menu-icon">
                          <DownOutlined />
                        </span>
                      </Dropdown>
                      <Avatar
                        size={50}
                        icon={<UserOutlined />}
                        src={
                          user.user_metadata && user.user_metadata.avatar_url && (
                            <img
                              src={`${user.user_metadata.avatar_url
                                }?${new Date().getTime()}`}
                              alt="avatar"
                            />
                          )
                        }
                      />
                    </div>
                  )}
                  <div>
                    <h2>{props.title}</h2>
                    <h5>{props.subtitle}</h5>
                  </div>
                </div>
              </div>
              <div className="header-right">
                <div>{props.header}</div>
                {props.sidebarBody &&
                  <div className="sidebar-toggle-container">
                    <Button size="large" shape="circle" type="text" onClick={toggleMobileSidebar} className="toggle-sidebar-button">
                      <MenuOutlined className="toggle-sidebar-icon" />
                    </Button>
                  </div>
                }
              </div>
            </div>
            <div className="content-body">
              <div>
                {props.isLoading ? (
                  <div className="loader-container">
                    <ClipLoader
                      color="#5271FA"
                      loading={true}
                      size={60}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </div>
                ) : (
                  props.body
                )}
              </div>
            </div>
          </div>
          {props.sidebarBody && (
            <div className="sidebar">
              <div className="sidebar-header">
                <div>
                  <h3>{props.sidebarTitle}</h3>
                  <h5 className="sidebar-subtitle">{props.sidebarSubtitle}</h5>
                </div>
                <div>{props.sidebarAction}</div>
              </div>
              <div className="sidebar-body">{props.sidebarBody}</div>
            </div>
          )}
        </div>
      </div>
    </ConfigProvider>
  );
}

export default PageLayout;
