import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { AuthService } from "../services/authService";

const browserLanguage = navigator.language;

const user = AuthService.getUser();
let lang = browserLanguage.startsWith("it") ? "it" : "en";
if (user) {
  lang = user.user_metadata.lang;
}

i18n.use(initReactI18next).init({
  lng: lang,
  fallbackLng: "en",
  debug: process.env.NODE_ENV !== "development",
  resources: {
    it: {
      translation: require("../translation/it.json"),
    },
    en: {
      translation: require("../translation/en.json"),
    },
  },
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
