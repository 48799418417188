import { useEffect, useState } from "react";
import "./dashboardStyle.css";
import { Button, Empty, Modal } from "antd";
import { IoIosAddCircleOutline, IoMdAdd } from "react-icons/io";
import { t } from "i18next";
import { IExam } from "../../interfaces/exam";
import SubjectCard from "../../components/subjectCard/SubjectCard";
import ExamCard from "../../components/examCard/ExamCard";
import FilterSelect from "../../components/filterSelect/FilterSelect";
import { ExamService } from "../../services/examService";
import { AuthService } from "../../services/authService";
import CreateExamModal from "./modals/createExam/createExamModal";
import { ISubject } from "../../interfaces/subject";
import { SubjectService } from "../../services/subjectService";
import { ExamFilter } from "../../enums/examFilter.enum";
import { Link } from "react-router-dom";
import PageLayout from "../../components/pageLayout/pageLayout";
import CreateSubjectModal from "./modals/createSubject/CreateSubjectModal";
import { IUser } from "../../interfaces/user";
import { RxExit } from "react-icons/rx";
import { AppRoutes } from "../../routes/routes";
import Logo from "../../components/logo/logo";
import { PaymentService } from "../../services/paymentService";

const Dashboard = () => {
  const [exams, setExams] = useState<IExam[]>([]);
  const [subjects, setSubjects] = useState<ISubject[]>([]);
  const [selectedExamFilter, setSelectedExamFilter] = useState<ExamFilter>(
    ExamFilter.ALL
  );
  const [isLoadingExams, setIsLoadingExams] = useState<boolean>(true);
  const [isSubjectModal, setIsSubjectModal] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [user, setUser] = useState<IUser>();

  async function loadExams() {
    setIsLoadingExams(true);
    const exams: IExam[] = await ExamService.getAll(selectedExamFilter);
    setExams(exams);
    setIsLoadingExams(false);
  }

  async function loadSubjects() {
    const subjects: ISubject[] = await SubjectService.getAll();
    setSubjects(subjects);
  }

  async function onExamFilterChange(filter: ExamFilter): Promise<void> {
    setSelectedExamFilter(filter);
  }

  function loadUser() {
    setUser(AuthService.getUser());
  }

  const openSubjectModal = () => {
    setIsSubjectModal(true);
  };

  const closeSubjectModal = () => {
    setIsSubjectModal(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onExamCreated = () => {
    closeModal();
    loadExams();
  };

  const onSubjectCreated = () => {
    closeSubjectModal();
    loadSubjects();
  };

  const checkUserPayments = async () => {
    await PaymentService.updateUserPlan();
  };

  useEffect(() => {
    document.title = `PassMate | ${t("generics.phrases.dashboard")}`;
    const fetchData = async () => {
      checkUserPayments();
      loadUser();
      loadSubjects();
    };

    fetchData();
  }, []);

  useEffect(() => {
    loadExams();
  }, [selectedExamFilter]);

  return (
    <PageLayout
      title={t("pages.dashboard.hello", {
        firstName: user?.user_metadata.full_name.split(" ")[0],
      })}
      subtitle={t("pages.dashboard.subtitle")}
      header={
        <div className="filter-search">
          <FilterSelect onSelect={onExamFilterChange} />
          <Button
            className="navbar-btn"
            type="primary"
            icon={<IoMdAdd />}
            onClick={openModal}
          >
            {t("pages.dashboard.exams.add_exam")}
          </Button>
        </div>
      }
      isLoading={isLoadingExams}
      body={
        <>
          {exams.length === 0 && (
            <div className="empty-container">
              <Empty description={t("pages.dashboard.exams.empty_data")} />
            </div>
          )}
          <div className="user-card">
            {exams.length > 0 &&
              exams.map((exam) => (
                <Link
                  to={"exam?id=" + exam.id}
                  className="text-link"
                  key={exam.id}
                >
                  <ExamCard {...exam}></ExamCard>
                </Link>
              ))}
          </div>
        </>
      }
      sidebarTitle={t("pages.dashboard.subjects")}
      sidebarBody={
        <div className="sidebar-content">
          <div>
            <div className="sidebar-item">
              {subjects.length === 0 && (
                <p className="text">{t("pages.dashboard.exams.no_subjects")}</p>
              )}
              {subjects.map((subject) => (
                <SubjectCard
                  subject={subject}
                  key={subject.id}
                  onUpdate={loadSubjects}
                ></SubjectCard>
              ))}
            </div>
            <div className="sidebar-center">
              <Button
                className="sidebar-btn"
                type="default"
                icon={<IoIosAddCircleOutline />}
                onClick={openSubjectModal}
              >
                {t("common.add")}
              </Button>
              <CreateExamModal
                isOpen={isModalOpen}
                onClose={closeModal}
                subjects={subjects}
                onCreate={onExamCreated}
              ></CreateExamModal>

              <CreateSubjectModal
                isOpen={isSubjectModal}
                onClose={closeSubjectModal}
                onCreate={onSubjectCreated}
              ></CreateSubjectModal>
            </div>
          </div>
          <div className="sidebar-footer">
            <Logo />
          </div>
        </div>
      }
    ></PageLayout>
  );
};

export default Dashboard;
