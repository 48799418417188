import "./resetPassword.css";
import ResetForm from "../../components/resetForm/ResetForm";

const ResetPassword = () => {
  return (
    <div className="reset-page">
      <div className="center">
        <div>
          <ResetForm />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
