import { AuthResponse, AuthTokenResponsePassword } from "@supabase/supabase-js";
import { supabase } from "./supabaseClient";
import { IUser, IUserMetadata } from "../interfaces/user";
import { UtilsService } from "./utilsService";
import { Constants } from "../constants";
import { AppRoutes } from "../routes/routes";
import i18next from "i18next";
import { IUpdateUserForm } from "../pages/settings/settingsPage";

export class AuthService {
    static async signUp(email: string, password: string, fullName: string, schoolType: string): Promise<AuthResponse> {
        const response = await supabase.auth.signUp({
            email,
            password,
            options: {
                data: {
                    full_name: fullName,
                    lang: i18next.language,
                    school_type: schoolType
                },
                emailRedirectTo: process.env.REACT_APP_SITE_URL
            },
        });
        if (response.error) {
            UtilsService.showErrorToast(response.error.message);
            throw response.error;
        }
        return response;
    }

    static async sendResetPasswordRequest(email: string) {
        const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
            redirectTo: `${process.env.REACT_APP_SITE_URL}/${AppRoutes.RESET_PASSWORD}`,
        });
        if (error) {
            UtilsService.showErrorToast(error.message);
            throw error;
        }
    }

    static async resetPassword(newPassword: string) {
        const { data, error } = await supabase.auth.updateUser({
            password: newPassword
        });
        if (error) {
            UtilsService.showErrorToast(error.message);
            throw error;
        }
    }

    static async signIn(email: string, password: string, rememberMe?: boolean): Promise<AuthTokenResponsePassword> {
        const response = await supabase.auth.signInWithPassword({
            email,
            password,
        });
        if (!response.error) {
            if (rememberMe) {
                localStorage.setItem('user', JSON.stringify(response.data.user));
                localStorage.setItem('userToken', response.data.session.access_token);
            } else {
                sessionStorage.setItem('user', JSON.stringify(response.data.user));
                sessionStorage.setItem('userToken', response.data.session.access_token);
            }
        } else {
            throw response.error;
        }
        return response;
    }

    static saveUserData(response: any, rememberMe: boolean) {
        if (rememberMe) {
            localStorage.setItem('user', JSON.stringify(response.data.user));
            localStorage.setItem('userToken', response.data.session.access_token);
        } else {
            sessionStorage.setItem('user', JSON.stringify(response.data.user));
            sessionStorage.setItem('userToken', response.data.session.access_token);
        }
    }

    static async signInWithGoogle() {
        const { data, error } = await supabase.auth.signInWithOAuth({
            provider: 'google',
            options: {
                queryParams: {
                    access_type: 'offline',
                    prompt: 'consent',
                },

                redirectTo: `${process.env.REACT_APP_SITE_URL}/${AppRoutes.GOOGLE_SIGN_IN}`
            },
        })
        if (error) {
            UtilsService.showErrorToast(error.message);
            throw error;
        }
    }

    static async handleSignInWithGoogle() {
        const { error, data } = await supabase.auth.getSession();
        if (error) {
            UtilsService.showErrorToast(error.message);
            throw error;
        } else {

            const userData: any = Object.assign({}, data.session?.user);
            const defaultSchoolType = 'high_school';

            // Check for custom user metadata
            if (!data.session?.user.user_metadata.lang) {
                await supabase.auth.updateUser({ data: { lang: i18next.language } });
                userData.user_metadata.lang = i18next.language;
            }
            if (!data.session?.user.user_metadata.school_type) {
                await supabase.auth.updateUser({ data: { school_type: defaultSchoolType } });
                userData.user_metadata.school_type = defaultSchoolType;
            }

            localStorage.setItem('user', JSON.stringify(userData));
            localStorage.setItem('userToken', data.session?.access_token!);
            window.location.replace('/');
        }
    }

    static async logout(): Promise<void> {
        localStorage.removeItem('user');
        localStorage.removeItem('userToken');
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('userToken');
        const { error } = await supabase.auth.signOut();
        if (error) {
            UtilsService.showErrorToast(error.message);
            throw error;
        }
    }

    static getUser(): IUser {
        if (sessionStorage.getItem('user')) {
            return JSON.parse(sessionStorage.getItem('user')!) as IUser;
        } else {
            return JSON.parse(localStorage.getItem('user')!) as IUser;
        }
    }

    static async updateUserInfo(userInfo: IUserMetadata): Promise<void> {
        const { error } = await supabase.auth.updateUser({ data: userInfo });
        if (error) {
            UtilsService.showErrorToast(error.message);
            throw error;
        } else {
            if (sessionStorage.getItem('user')) {
                const storedUser: IUser = JSON.parse(sessionStorage.getItem('user')!);
                sessionStorage.setItem('user', JSON.stringify({ ...storedUser, user_metadata: userInfo }));
            }
            if (localStorage.getItem('user')) {
                const storedUser: IUser = JSON.parse(localStorage.getItem('user')!);
                localStorage.setItem('user', JSON.stringify({ ...storedUser, user_metadata: userInfo }));
            }
        }
    }

    static async uploadAvatarImage(avatarFile: File) {
        const user = AuthService.getUser();
        const url = await supabase.storage.from('avatars').getPublicUrl(`${user.id}.png`);
        if (url) {
            await supabase.storage.from('avatars').remove([`${user.id}.png`]);
        }
        const { data, error } = await supabase
            .storage
            .from('avatars')
            .upload(`${user.id}.png`, avatarFile, {
                cacheControl: '3600',
                upsert: false,
            })
        if (error) {
            UtilsService.showErrorToast(error.message);
            throw error;
        } else {
            return data;
        }
    }

    static async checkUserSession() {
        const { data, error } = await supabase.auth.getSession();
        if (!data.session) {
            const response = await supabase.auth.reauthenticate();
            if (response.error) {
                await AuthService.logout();
            } else {
                window.location.reload();
            }
        }
    }
}