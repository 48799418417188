import React, { useEffect, useState } from 'react';
import { RiArrowDropDownLine } from "react-icons/ri";
import type { MenuProps } from 'antd';
import { Dropdown, Space, Typography } from 'antd';
import './filterSelect.css';
import { t } from 'i18next';
import { ExamFilter } from '../../enums/examFilter.enum';

const items = [{
  key: ExamFilter.ALL,
  label: t('pages.dashboard.exams.filter_all'),
},
{
  key: ExamFilter.NOT_COMPLETED,
  label: t('pages.dashboard.exams.filter_not_completed'),
},
{
  key: ExamFilter.COMPLETED,
  label: t('pages.dashboard.exams.filter_completed'),
},
{
  key: ExamFilter.PASSED,
  label: t('pages.dashboard.exams.filter_passed'),
},
{
  key: ExamFilter.NOT_PASSED,
  label: t('pages.dashboard.exams.filter_not_passed'),
}];

const FilterSelect: React.FC<any> = (props: { onSelect: any }) => {
  const [selected, setSelected] = useState<any>();
  const [items, setItems] = useState<any[]>([]);

  async function loadItems(): Promise<void> {
    setItems([{
      key: ExamFilter.ALL,
      label: t('pages.dashboard.exams.filter_all'),
    },
    {
      key: ExamFilter.NOT_COMPLETED,
      label: t('pages.dashboard.exams.filter_not_completed'),
    },
    {
      key: ExamFilter.COMPLETED,
      label: t('pages.dashboard.exams.filter_completed'),
    },
    {
      key: ExamFilter.PASSED,
      label: t('pages.dashboard.exams.filter_passed'),
    },
    {
      key: ExamFilter.NOT_PASSED,
      label: t('pages.dashboard.exams.filter_not_passed'),
    },]);
  }

  useEffect(() => {
    loadItems();
    setSelected({ key: ExamFilter.ALL, label: t('pages.dashboard.exams.filter_all') });
  }, []);

  const onClick: MenuProps['onClick'] = ({ key }) => {
    const selectedItem = items.find(item => item?.key === key);
    setSelected(selectedItem);
    props.onSelect(selectedItem!.key);
  };

  return <Dropdown
    menu={{
      defaultValue: ExamFilter.ALL,
      items,
      selectable: true,
      onClick
    }}
    className='filter-search'
  >
    <Typography.Link>
      <Space>
        <strong>{selected ? selected.label : ''}</strong>
        <RiArrowDropDownLine />
      </Space>
    </Typography.Link>
  </Dropdown>
};

export default FilterSelect;