import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthService } from "../../services/authService";
import { AppRoutes } from "../../routes/routes";

const GoogleSignInPage = () => {
    const navigate = useNavigate();

    async function load() {
        const url = window.location.href;
        const hash = url.substring(url.indexOf('#') + 1);
        if (hash) {
            let result = hash.split('&')
            result = result[0].split('=')
            const accessToken = result[1];
            try {
                await AuthService.handleSignInWithGoogle();
            } catch (e) {
                navigate(`/${AppRoutes.SIGN_IN}`);
            }
        } else {
            window.location.replace(`/${AppRoutes.SIGN_IN}`);
        }
    }

    useEffect(() => {
        load();
    }, []);

    return <></>;
}

export default GoogleSignInPage;