import { useState, useRef, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useForm, SubmitHandler, FieldError } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import "./signUpForm.css";
import { AuthService } from "../../services/authService";
import { UtilsService } from "../../services/utilsService";
import { useNavigate } from "react-router-dom";
import GoogleLogin from "../googleLogin/GoogleLogin";
import { AppRoutes } from "../../routes/routes";
import { Constants } from "../../constants";

interface ISignUpFormData {
  name: string;
  lastName: string;
  email: string;
  schoolType: string;
  password: string;
  confirmPassword: string;
}

const SignUpForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataForm, setDataForm] = useState<ISignUpFormData>({
    name: "",
    lastName: "",
    email: "",
    schoolType: "",
    password: "",
    confirmPassword: "",
  });

  const validationSchema = z.object({
    name: z
      .string()
      .max(14, { message: t("generics.forms.login_form_max_character") })
      .min(1, { message: t("generics.forms.isRequired") }),
    lastName: z
      .string()
      .max(14, { message: t("generics.forms.login_form_max_character") })
      .min(1, { message: t("generics.forms.isRequired") }),
    email: z
      .string()
      .max(60, { message: t("generics.forms.login_form_email_max_character") })
      .email({ message: t("generics.forms.login_form_valid_email") })
      .min(1, { message: t("generics.forms.isRequired") }),
    schoolType: z.string(),
    password: z
      .string()
      .min(6, { message: t("generics.forms.login_form_min_character") })
      .max(40, { message: t("generics.forms.login_form_max_character") })
      .refine(
        (password) => {
          const regex =
            /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-/./_]).{8,}$/;
          return regex.test(password);
        },
        { message: t("generics.forms.login_form_valid_password") }
      ),
    confirmPassword: z
      .string()
      .min(6, { message: t("generics.forms.login_form_min_character") })
      .max(40, { message: t("generics.forms.login_form_max_character") }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ISignUpFormData>({
    resolver: zodResolver(validationSchema),
  });

  const onChangePost = (field: keyof ISignUpFormData, value: string) => {
    setDataForm((prevDataForm) => ({
      ...prevDataForm,
      [field]: value,
    }));
  };

  const handleGoogleLoginClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
  };

  const onSubmit: SubmitHandler<ISignUpFormData> = async (formData) => {
    setIsLoading(true);
    try {
      const response = await AuthService.signUp(
        formData.email,
        formData.password,
        `${formData.name} ${formData.lastName}`,
        formData.schoolType
      );
      if (formRef.current) {
        formRef.current.reset();
        UtilsService.showSuccessToast(
          t("generics.forms.signUp_sub_success"),
          true
        );
      }
      navigate(`/${AppRoutes.SIGN_IN}`);
    } catch (err) {
      UtilsService.showErrorToast(t("generics.forms.signUp_sub_error"));
      console.error(err);
    }
    setIsLoading(false);
  };

  return (
    <div className="signUp-form-section">
      <h2 className="signUp-form-title">
        {t("generics.forms.signUp_form_title")}
      </h2>
      <p className="signUp-form-sub-title">
        {t("generics.forms.signUp_form_sub_title")}
      </p>
      <form
        onSubmit={handleSubmit(onSubmit)}
        ref={formRef}
        className="signUp-form"
      >
        <div className="label-container">
          <div className="signUp-formControl">
            <label htmlFor="name">{t("generics.forms.signUp_form_name")}</label>
            <input
              disabled={isLoading}
              id="name"
              type="name"
              placeholder={t("generics.forms.signUp_name_suggestion")}
              {...register("name")}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChangePost("name", e.target.value)
              }
            />
            {errors.name && (
              <small className="signUp-error">
                {(errors.name as FieldError).message}
              </small>
            )}
          </div>

          <div className="signUp-formControl">
            <label htmlFor="lastName">
              {t("generics.forms.signUp_form_last_name")}
            </label>
            <input
              disabled={isLoading}
              id="lastName"
              type="lastName"
              placeholder={t("generics.forms.signUp_last_name_suggestion")}
              {...register("lastName")}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChangePost("lastName", e.target.value)
              }
            />
            {errors.lastName && (
              <small className="signUp-error">
                {(errors.lastName as FieldError).message}
              </small>
            )}
          </div>
        </div>

        <div className="label-container">
          <div className="signUp-formControl">
            <label htmlFor="email">
              {t("generics.forms.signUp_form_email")}
            </label>
            <input
              disabled={isLoading}
              id="email"
              type="email"
              placeholder={t("generics.forms.signUp_email_suggestion")}
              {...register("email")}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChangePost("email", e.target.value)
              }
            />
            {errors.email && (
              <small className="signUp-error">
                {(errors.email as FieldError).message}
              </small>
            )}
          </div>

          <div className="signUp-formControl">
            <label id="schoolType" htmlFor="schoolType">{t("generics.forms.signUp_form_school_type")}</label>
            <select id="schoolType"  {...register("schoolType")} onChange={(e: any) =>
              onChangePost("schoolType", e.target.value)
            }>
              {Constants.AVAILABLE_SCHOOL_TYPES.map((schoolType) => (
                <option value={schoolType}>{UtilsService.getSchoolTypeString(schoolType)}</option>
              ))}
            </select>
            {errors.schoolType && (
              <small className="signUp-error">
                {(errors.schoolType as FieldError).message}
              </small>
            )}
          </div>
        </div>

        <div className="label-container">
          <div className="signUp-formControl">
            <label htmlFor="password">
              {t("generics.forms.signUp_form_password")}
            </label>
            <input
              disabled={isLoading}
              id="password"
              type="password"
              placeholder={t("generics.forms.signUp_password_suggestion")}
              {...register("password")}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChangePost("password", e.target.value)
              }
            />
            {errors.password && (
              <small className="signUp-error">
                {(errors.password as FieldError).message}
              </small>
            )}
          </div>

          <div className="signUp-formControl">
            <label htmlFor="confirmPassword">
              {t("generics.forms.signUp_form_confirmPassword")}
            </label>
            <input
              disabled={isLoading}
              id="confirmPassword"
              type="password"
              placeholder={t("generics.forms.signUp_password_suggestion")}
              {...register("confirmPassword")}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChangePost("confirmPassword", e.target.value)
              }
            />
            {errors.confirmPassword && (
              <small className="signUp-error">
                {(errors.confirmPassword as FieldError).message}
              </small>
            )}
          </div>
        </div>
        <div className="button-signUp-container">
          <button type="submit" className="signUp-btn" disabled={isLoading}>
            {t("generics.forms.signUp_form_title")}
          </button>
          <GoogleLogin
            className="signUp-google-btn"
            text={t("generics.google_login.signUp_title")}
            onClick={handleGoogleLoginClick}
          />
        </div>
      </form>
    </div>
  );
};

export default SignUpForm;
