import React from "react";
import GoogleIcon from "../../assets/images/google.webp";
import "./googleLogin.css";
import { AuthService } from "../../services/authService";

interface GoogleButtonProps {
  className?: string;
  text: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const GoogleLogin: React.FC<GoogleButtonProps> = ({
  className,
  text,
  onClick,
}) => {
  const buttonClasses = "google-btn" + (className ? ` ${className}` : "");

  const hanldeClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    await AuthService.signInWithGoogle();
    onClick(e);
  };

  return (
    <button className={buttonClasses} onClick={hanldeClick}>
      <img src={GoogleIcon} alt="google-icon" />
      <p>{text}</p>
    </button>
  );
};

export default GoogleLogin;
