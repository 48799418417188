import { Button, CollapseProps, Modal } from "antd";
import { useState } from "react";
import { ExamService } from "../../../../services/examService";
import { UtilsService } from "../../../../services/utilsService";
import { t } from "i18next";
import "./deleteExamModal.css";
import { IExam } from "../../../../interfaces/exam";
import { useNavigate } from "react-router-dom";
import { SimulationService } from "../../../../services/simulationService";
import { ISimulation } from "../../../../interfaces/simulation";

const DeleteExamModal: React.FC<any> = (props: { isOpen: boolean, onClose: any, exam: IExam, onCreate: any, simulations: CollapseProps['items'] }) => {
    const navigate = useNavigate();
    const [isDeleting, setIsDeleting] = useState(false);

    const onDelete = async () => {
        setIsDeleting(true);
        await SimulationService.delete(props.simulations?.map(item => Number.parseInt(item.key!.toString()))!);
        await ExamService.delete(props.exam.id!);
        UtilsService.showSuccessToast(t('pages.dashboard.exams.delete_success'));
        navigate(-1);
    };

    return <Modal
        open={props.isOpen}
        title={t('pages.dashboard.exams.delete_exam')}
        onCancel={props.onClose}
        footer={[
            <Button onClick={props.onClose} key="close">
                {t('common.return')}
            </Button>,
            <Button danger type="primary" loading={isDeleting} onClick={onDelete} key="delete">
                {t('common.delete')}
            </Button>
        ]}
    >
        <p>{t('pages.dashboard.exams.delete_exam_question', { examName: props.exam.name })}</p>
    </Modal>
}

export default DeleteExamModal;