import { useEffect, useState } from "react";
import PageLayout from "../../components/pageLayout/pageLayout";
import { ExamService } from "../../services/examService";
import { Link, useSearchParams } from "react-router-dom";
import { IExam } from "../../interfaces/exam";
import i18next, { t } from "i18next";
import {
  Button,
  Card,
  Col,
  Collapse,
  CollapseProps,
  Divider,
  Empty,
  Flex,
  Row,
  Statistic,
  Tag,
} from "antd";
import { IoMdAdd } from "react-icons/io";
import CreateSimulationModal from "../dashboard/modals/createSimulation/createSimulationModal";
import { ISimulation } from "../../interfaces/simulation";
import { SimulationService } from "../../services/simulationService";
import moment from "moment";
import {
  EditOutlined,
  DeleteOutlined,
  LikeFilled,
  DislikeFilled,
} from "@ant-design/icons";
import DeleteExamModal from "../dashboard/modals/deleteExam/deleteExamModal";
import "./examPage.css";
import { UtilsService } from "../../services/utilsService";
import CreateExamModal from "../dashboard/modals/createExam/createExamModal";
import { SubjectService } from "../../services/subjectService";
import { ISubject } from "../../interfaces/subject";
import { Constants } from "../../constants";
import NotFound from "../404/NotFound";
import Logo from "../../components/logo/logo";
import 'moment/locale/it';

const ExamPage = () => {
  moment.locale(i18next.language);

  const [searchParams, setSearchParams] = useSearchParams();
  const [subjects, setSubjects] = useState<ISubject[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [exam, setExam] = useState<IExam>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [simulations, setSimulations] = useState<CollapseProps["items"]>([]);
  const [isDeleteModalOpen, setIsDeleteModalopen] = useState<boolean>(false);
  const [passedSimulations, setPassedSimulations] = useState<number>(0);
  const [successRate, setSuccessRate] = useState<number>(0);
  const [notFound, setNotFound] = useState<boolean>(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleDeleteExamModal = () => {
    setIsDeleteModalopen(!isDeleteModalOpen);
  };

  const toggleEditExamModal = () => {
    setIsEditModalOpen(!isEditModalOpen);
  };

  function updateExamState(passed: boolean) {
    const updatedExam = Object.assign({}, exam!);
    updatedExam.passed = passed;
    ExamService.update(updatedExam);
    setExam(updatedExam);
  }

  async function load(): Promise<void> {
    setIsLoading(true);
    loadSubjects();
    await loadExam();
    const examId = Number.parseInt(searchParams.get("id")!);
    const response: ISimulation[] = await SimulationService.getAll(examId!);

    const simulations = [];
    let passedSimulations = 0;
    for (let i = 0; i < response.length; i++) {
      const simulation = response[i];
      const simulationAnswers: any = JSON.parse(simulation.answers_json);
      const totalAnswers =
        simulation.answers_json !== "{}" ? simulationAnswers.length : 0;
      const maxPoints =
        simulation.total_questions * Constants.MAX_ANSWER_POINTS;
      const passedPercentage = (simulation.points * 100) / maxPoints;
      const passed = passedPercentage >= 60;
      if (passed) {
        passedSimulations++;
      }
      const item = {
        key: simulation.id.toString(),
        label: `${t("pages.dashboard.exams.simulation")} #${response.length - i
          }`,
        children: (
          <div>
            <p className="text">
              <strong>{t("pages.dashboard.exams.type")}: </strong>
              {UtilsService.getQuestionsTypeStringFromEnum(simulation.type)}
              <br />
              <strong>{t("pages.dashboard.exams.answers")}: </strong>
              {totalAnswers} / {simulation.total_questions}
              <br />
              <strong>{t("pages.dashboard.exams.points")}: </strong>
              {simulation.points} / {maxPoints}
            </p>
            {simulation.finished_date && (
              <p className="text-sm">
                <strong>{t("pages.dashboard.exams.completed_at")}:</strong>{" "}
                {moment.utc(simulation.finished_date).local().calendar()}
              </p>
            )}
            <p className="text-sm simulation-creation-date">
              <strong>{t("pages.dashboard.exams.created_at")}:</strong>{" "}
              {moment.utc(simulation.created_at).local().calendar()}
            </p>
            <Link to={"/simulation?id=" + simulation.id} className="text-link">
              <Button type="primary">{t("common.open")}</Button>
            </Link>
          </div>
        ),
        extra: simulation.finished_date ? (
          passed ? (
            <Tag color="green">
              {t("pages.dashboard.exams.simulation_passed")}
            </Tag>
          ) : (
            <Tag color="red">
              {t("pages.dashboard.exams.simulation_not_passed")}
            </Tag>
          )
        ) : (
          <Tag>{t("pages.dashboard.exams.simulation_not_completed")}</Tag>
        ),
      };
      simulations.push(item);
    }
    setSimulations(simulations);
    setPassedSimulations(passedSimulations);
    setSuccessRate((passedSimulations * 100) / simulations.length);

    setIsLoading(false);
  }

  async function loadExam() {
    try {
      const examId = Number.parseInt(searchParams.get("id")!);
      setExam(await ExamService.get(examId));
    } catch (e) {
      setNotFound(true);
    }
  }

  async function loadSubjects() {
    const response = await SubjectService.getAll();
    setSubjects(response);
  }

  function onCreated() {
    closeModal();
    load();
  }

  function onUpdated() {
    toggleEditExamModal();
    loadExam();
  }

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    document.title = `PassMate | ${exam?.name}`;
  }, [exam]);

  return notFound ? (
    <NotFound />
  ) : (
    <PageLayout
      previousRoute="/"
      isLoading={isLoading}
      title={exam?.subject.name}
      subtitle={exam?.name}
      header={
        <Button
          className="navbar-btn"
          type="primary"
          icon={<IoMdAdd />}
          onClick={openModal}
        >
          {t("pages.dashboard.exams.new_simulation")}
        </Button>
      }
      sidebarTitle={t("pages.dashboard.exams.info")}
      sidebarAction={
        <div>
          <Button
            type="text"
            danger
            icon={<DeleteOutlined />}
            onClick={toggleDeleteExamModal}
          ></Button>
          <Button
            type="text"
            icon={<EditOutlined />}
            onClick={toggleEditExamModal}
          ></Button>
        </div>
      }
      sidebarSubtitle={exam?.subject?.name}
      sidebarBody={
        <div>
          <Divider />
          {exam?.passed && (
            <Tag color="success">{t("pages.dashboard.exams.passed_exam")}</Tag>
          )}
          {!exam?.passed && exam?.passed !== null && (
            <Tag color="error">
              {t("pages.dashboard.exams.not_passed_exam")}
            </Tag>
          )}
          {moment(exam?.datetime).isBefore(moment()) &&
            exam?.passed !== null && (
              <p className="text exam-date">
                <strong>{t("pages.dashboard.exams.exam_done_at")}</strong>{" "}
                {moment(exam?.datetime).calendar()}
              </p>
            )}
          {moment(exam?.datetime).isBefore(moment()) &&
            exam?.passed === null && (
              <div>
                <p className="text">
                  {t("pages.dashboard.exams.passed_exam_date")}
                </p>
                <Flex gap="middle">
                  <Button
                    icon={<LikeFilled />}
                    onClick={() => updateExamState(true)}
                  >
                    {t("pages.dashboard.exams.passed_exam")}
                  </Button>
                  <Button
                    danger
                    icon={<DislikeFilled />}
                    onClick={() => updateExamState(false)}
                  >
                    {t("pages.dashboard.exams.not_passed_exam")}
                  </Button>
                </Flex>
              </div>
            )}
          {moment(exam?.datetime).isAfter(moment()) &&
            exam?.passed === null && (
              <p className="text exam-date">
                {t("pages.dashboard.exams.exam_set_for")}{" "}
                <strong>{moment.utc(exam?.datetime).local().calendar()}</strong>
              </p>
            )}
          {exam && (
            <CreateExamModal
              isOpen={isEditModalOpen}
              onClose={toggleEditExamModal}
              onUpdate={onUpdated}
              exam={exam}
              subjects={subjects}
              simulations={simulations}
            ></CreateExamModal>
          )}
          {exam && (
            <CreateSimulationModal
              isOpen={isModalOpen}
              onClose={closeModal}
              exam={exam}
              onCreate={onCreated}
            ></CreateSimulationModal>
          )}
          {exam && (
            <DeleteExamModal
              isOpen={isDeleteModalOpen}
              onClose={toggleDeleteExamModal}
              exam={exam}
              onCreate={onCreated}
              simulations={simulations}
            ></DeleteExamModal>
          )}
          <div className="sidebar-footer-exam">
            <Logo useGreyMode={true} />
          </div>
        </div>
      }
      body={
        <div>
          {simulations && simulations.length > 0 && (
            <div>
              <div className="exam-card-results-row">
                <Card
                  bordered={false}
                  className="exam-card-results-simulation"
                >
                  <Statistic
                    title={t("pages.dashboard.exams.passed_simulations")}
                    value={passedSimulations}
                    suffix={`/ ${simulations!.length}`}
                    valueStyle={{
                      color:
                        passedSimulations >= simulations!.length / 2
                          ? "#3f8600"
                          : "#cf1322",
                    }}
                  />
                </Card>
                <Card
                  bordered={false}
                  className="exam-card-results-simulation"
                >
                  <Statistic
                    title={t("pages.dashboard.exams.success_rate")}
                    value={successRate}
                    precision={0}
                    suffix={"%"}
                    valueStyle={{
                      color: successRate >= 60 ? "#3f8600" : "#cf1322",
                    }}
                  />
                </Card>
              </div>
              <Divider />
            </div>
          )}
          {simulations && simulations.length > 0 && (
            <div>
              <h3 className="simulations-title">
                {t("pages.dashboard.exams.simulations")}
              </h3>
              <Collapse
                accordion
                items={simulations}
                style={{ background: Constants.COLOR_PRIMARY_ALPHA }}
              />
            </div>
          )}
          {!simulations ||
            (simulations.length === 0 && (
              <div className="loader-container">
                <Empty
                  description={t("pages.dashboard.exams.no_simulations")}
                />
              </div>
            ))}
        </div>
      }
    ></PageLayout>
  );
};

export default ExamPage;
