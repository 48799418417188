import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { SimulationService } from "../../services/simulationService";
import { ISimulation } from "../../interfaces/simulation";
import PageLayout from "../../components/pageLayout/pageLayout";
import i18next, { t } from "i18next";
import 'moment-timezone';
import {
  Alert,
  Button,
  Collapse,
  CollapseProps,
  Radio,
  Space,
  Tag,
} from "antd";
import { Constants } from "../../constants";
import {
  AudioOutlined,
  AudioMutedOutlined,
  SoundOutlined,
  MutedOutlined,
} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import "./simulationPage.css";
import { UtilsService } from "../../services/utilsService";
import moment from "moment";
import { ScaleLoader } from "react-spinners";
import { QuestionsType } from "../../enums/questionsType.enum";
import NotFound from "../404/NotFound";
import { AppRoutes } from "../../routes/routes";
import Logo from "../../components/logo/logo";

interface QuestionsObject {
  questions: QuestionItem[];
}

interface QuestionItem {
  question: string;
  options?: string[];
}

export interface AnswerItem {
  answer: string;
  points: number;
  solution: string;
}

let autoSaveTimeout: any;

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;
const recognition = new SpeechRecognition();
recognition.continuous = false;
recognition.lang = navigator.language;
recognition.interimResults = false;
recognition.maxAlternatives = 1;
let stopRecognition = false;

var synth = window.speechSynthesis;

const SimulationPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [simulation, setSimulation] = useState<ISimulation>();
  const [questions, setQuestions] = useState<CollapseProps["items"]>([]);
  const [answers, setAnswers] = useState<any>({});
  const [isCorrecting, setIsCorrecting] = useState<boolean>(false);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [openedSolutionId, setOpenedSolutionId] = useState<number>();
  const [correctAnswers, setCorrectAnswers] = useState<number>(0);
  const [openedQuestion, setOpenedQuestion] = useState<number>();
  const [listening, setListening] = useState<boolean>(false);
  const [transcription, setTranscription] = useState<string>("");
  const [isReproducing, setIsReproducing] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [dynamicProps, setDynamicProps] = useState<any>({});

  recognition.onresult = (event) => {
    setTranscription(event.results[0][event.results[0].length - 1].transcript);
  };

  recognition.onend = (event) => {
    if (!stopRecognition) recognition.start();
  };

  function toggleListening() {
    if (!listening) {
      stopRecognition = false;
      recognition.start();
    } else {
      stopRecognition = true;
      recognition.stop();
    }
    setListening(!listening);
  }

  function onAnswer(answer: AnswerItem, index: number, useAutoSave?: boolean) {
    if (listening) {
      setDynamicProps({value: answer.answer});
    } else {
      setDynamicProps({});
    }
    if (!index && index !== 0) {
      return;
    }
    if (answer.answer.trim() === "") {
      delete answers[index];
    } else {
      answers[index] = { ...answer };
    }
    setAnswers({ ...answers });
    if (simulation && useAutoSave) {
      clearTimeout(autoSaveTimeout);
      autoSaveTimeout = setTimeout(() => {
        autoSave();
      }, 2000);
    }
  }

  function checkAnswerState(index: number) {
    const answer = answers[index - 1];
    if (!answer) {
      return "";
    }
    if (answer.solution === "") {
      return <Tag>{t("pages.dashboard.exams.answer_given")}</Tag>;
    } else {
      if (answer.points < 50) {
        return (
          <Tag color="red">
            {t("pages.dashboard.exams.wrong_answer")} ({answer.points} pt)
          </Tag>
        );
      } else if (answer.points >= 50 && answer.points < 60) {
        return (
          <Tag color="orange">
            {t("pages.dashboard.exams.not_complete_answer")} ({answer.points}{" "}
            pt)
          </Tag>
        );
      } else {
        return (
          <Tag color="green">
            {t("pages.dashboard.exams.correct_answer")} ({answer.points} pt)
          </Tag>
        );
      }
    }
  }

  function toggleTextToSpeech() {
    if (!isReproducing) {
      synth.speak(
        new SpeechSynthesisUtterance(answers[openedQuestion!].solution)
      );
    } else {
      synth.cancel();
    }
    setIsReproducing(!isReproducing);
  }

  async function load() {
    setIsLoading(true);
    try {
      const simulationId = Number.parseInt(searchParams.get("id")!);
      const simulation = await SimulationService.get(simulationId);
      document.title = `PassMate | ${t("generics.phrases.simulation_exam", {
        examName: simulation?.exam.name,
      })}`;
      setSimulation(simulation);

      // Check if exam is completed
      const answersObject: AnswerItem[] = JSON.parse(simulation!.answers_json);
      if (simulation!.answers_json !== "{}") {
        if (
          answersObject.length === simulation.total_questions &&
          simulation.finished_date
        ) {
          setIsCompleted(true);
        }
        let correctAnswers = 0;
        for (let i = 1; i <= answersObject.length; i++) {
          const answer = answersObject[i - 1];
          onAnswer(answer, i - 1);
          if (answer.points >= 60) {
            correctAnswers++;
          }
        }
        setCorrectAnswers(correctAnswers);
      }
      setIsLoading(false);
    } catch (e) {
      setNotFound(true);
    }
  }

  function changeQuestion(event: string[]) {
    setOpenedQuestion(Number.parseInt(event[0]) - 1);
    if (listening) {
      recognition.stop();
      setListening(false);
    }
  }

  async function autoSave() {
    const simulationWithAnswers = Object.assign({}, simulation);
    const answersArray: AnswerItem[] = [];
    for (let value of Object.values(answers)) {
      answersArray.push(value as AnswerItem);
    }
    simulationWithAnswers.answers_json = JSON.stringify(answersArray);
    await SimulationService.update(simulationWithAnswers);
  }

  function toggleSolution(index: number) {
    if (index === openedSolutionId) {
      setOpenedSolutionId(undefined);
    } else {
      setOpenedSolutionId(index);
    }
  }

  function loadQuestions() {
    if (simulation) {
      const questionsFinal: any[] = [];
      const questionsObject: QuestionsObject = JSON.parse(
        simulation.questions_json
      );
      for (let i = 1; i <= questionsObject.questions.length; i++) {
        questionsFinal.push({
          key: i,
          label: `${i}. ${questionsObject.questions[i - 1].question}`,
          extra: <div>{checkAnswerState(i)}</div>,
          children: (
            <div className="textarea-container">
              {simulation.type === QuestionsType.OPEN && (
                <div>
                  <TextArea
                    onChange={(e: any) =>
                      onAnswer(
                        { answer: e.target.value, points: 0, solution: "" },
                        i - 1,
                        true
                      )
                    }
                    id={'textarea-' + i}
                    placeholder={t("pages.dashboard.exams.your_answer_here")}
                    autoSize={{ minRows: 3, maxRows: 6 }}
                    maxLength={500}
                    {...dynamicProps}
                    defaultValue={answers[i - 1] && answers[i - 1].answer}
                    disabled={listening || isCompleted || isCorrecting}
                    showCount
                  />
                  <div className="textarea-footer">
                    {!isCompleted && (
                      <div>
                        {listening ? (
                          <div className="listening-container">
                            <Button
                              onClick={toggleListening}
                              shape="circle"
                              type="text"
                              icon={
                                <AudioMutedOutlined
                                  style={{ color: Constants.COLOR_PRIMARY }}
                                />
                              }
                            ></Button>
                            <ScaleLoader
                              height={12}
                              width={3}
                              color={Constants.COLOR_PRIMARY}
                            />
                          </div>
                        ) : (
                          <Button
                            onClick={toggleListening}
                            shape="circle"
                            type="text"
                            icon={
                              <AudioOutlined
                                style={{ color: Constants.COLOR_PRIMARY }}
                              />
                            }
                          ></Button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
              {simulation.type === QuestionsType.MULTIPLE && (
                <div>
                  <Radio.Group
                    disabled={isCompleted || isCorrecting}
                    defaultValue={answers[i - 1] && answers[i - 1].answer}
                    onChange={(e: any) =>
                      onAnswer(
                        { answer: e.target.value, points: 0, solution: "" },
                        i - 1,
                        true
                      )
                    }
                  >
                    <Space direction="vertical">
                      {questionsObject.questions[i - 1].options?.map(
                        (option: string, i: number) => (
                          <Radio
                            value={option}
                            key={i}
                            className={isCompleted ? "disabled-radio" : ""}
                          >
                            {option}
                          </Radio>
                        )
                      )}
                    </Space>
                  </Radio.Group>
                </div>
              )}
              {answers[i - 1] && isCompleted && (
                <div className="solution-button">
                  <Button onClick={() => toggleSolution(i - 1)}>
                    {answers[i - 1] != undefined && openedSolutionId === i - 1
                      ? t("common.close")
                      : t("pages.dashboard.exams.read_solution")}
                  </Button>
                </div>
              )}
              {openedSolutionId != undefined && openedSolutionId === i - 1 ? (
                <Alert
                  message={t("pages.dashboard.exams.solution")}
                  description={answers[openedSolutionId].solution}
                  type="warning"
                  className="solution-box"
                  action={
                    <div>
                      <Button
                        className="reproducing-button-container"
                        size="small"
                        type="text"
                        shape={isReproducing ? "default" : "circle"}
                        icon={isReproducing ? "" : <SoundOutlined />}
                        onClick={toggleTextToSpeech}
                      >
                        {isReproducing && (
                          <ScaleLoader
                            height={12}
                            width={3}
                            color={Constants.COLOR_PRIMARY}
                          />
                        )}
                      </Button>
                    </div>
                  }
                />
              ) : (
                ""
              )}
            </div>
          ),
        });
      }
      setQuestions(questionsFinal);
    }
  }

  async function correct() {
    setIsCorrecting(true);
    UtilsService.showInfoToast(
      t("pages.dashboard.exams.correction_waiting_time")
    );
    const questionsObject: QuestionsObject = JSON.parse(
      simulation!.questions_json
    );
    const response: AnswerItem[] = await SimulationService.checkAnswers(
      simulation!.exam.name,
      questionsObject.questions.map((item) => item.question),
      (Object.values(answers) as AnswerItem[]).map(
        (item: AnswerItem) => item.answer
      ),
      simulation!.type
    );
    const simulationWithAnswers = Object.assign({}, simulation);
    simulationWithAnswers.answers_json = JSON.stringify(response);
    simulationWithAnswers.finished_date = new Date().toISOString();
    simulationWithAnswers.points = response.reduce(
      (partialSum, a) => partialSum + a.points,
      0
    );
    await SimulationService.update(simulationWithAnswers);
    window.location.reload();
  }

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    if (!transcription || transcription.trim() === "") {
      return;
    }
    const answer = answers[openedQuestion!];
    let text = "";
    if (answer) {
      text += answer.answer;
    }
    if (text.trim() !== "") text += " ";
    text += transcription.toLowerCase();
    onAnswer({ answer: text, points: 0, solution: "" }, openedQuestion!);
  }, [transcription]);

  useEffect(() => {
    loadQuestions();
  }, [simulation, openedSolutionId, listening, answers, isReproducing]);

  return notFound ? (
    <NotFound />
  ) : (
    <PageLayout
      previousRoute={`/exam?id=${simulation?.exam.id}`}
      isLoading={isLoading}
      title={t("pages.dashboard.exams.simulation")}
      subtitle={simulation?.exam.name}
      header={
        <div>
          <Logo useGreyMode={true} />
        </div>
      }
      body={
        <div className="simulation-body">
          <Collapse
            accordion
            onChange={(e: any) => changeQuestion(e)}
            items={questions}
            style={{ background: Constants.COLOR_PRIMARY_ALPHA }}
          ></Collapse>
          <div className="footer-container mt-1">
            {!isCompleted && (
              <p className="text-sm">
                <strong>{t("pages.dashboard.exams.answers")}: </strong>
                {answers != "{}" ? Object.keys(answers).length : 0} /{" "}
                {simulation?.total_questions}
              </p>
            )}
            {isCompleted && (
              <p className="text-sm">
                <strong>{t("pages.dashboard.exams.answers")}: </strong>
                {correctAnswers} {t('pages.simulation.answer_result')}  {simulation!.total_questions}
                <br />
                <strong>{t("pages.dashboard.exams.points")}: </strong>
                {simulation?.points} {t('pages.simulation.score_result')} {" "}
                {simulation!.total_questions * Constants.MAX_ANSWER_POINTS}
                <br />
                {(simulation!.points * 100) /
                  (simulation!.total_questions * Constants.MAX_ANSWER_POINTS) >=
                60 ? (
                  <Tag color="green" className="mt-1">
                    {t("pages.dashboard.exams.simulation_passed")}
                  </Tag>
                ) : (
                  <Tag color="red" className="mt-1">
                    {t("pages.dashboard.exams.simulation_not_passed")}
                  </Tag>
                )}
              </p>
            )}
            <div className="footer-right">
              {isCompleted && (
                <p className="text-sm">
                  {t("pages.dashboard.exams.completed_at")}:{" "}
                  <strong>
                    {moment.utc(simulation?.finished_date).local().calendar()}
                  </strong>
                </p>
              )}
              <Button
                type="primary"
                loading={isCorrecting}
                disabled={
                  isCompleted ||
                  Object.keys(answers).length <
                    (simulation ? simulation?.total_questions : 0)
                }
                onClick={correct}
              >
                {t("pages.dashboard.exams.correct")}
              </Button>
            </div>
          </div>
        </div>
      }
    ></PageLayout>
  );
};

export default SimulationPage;
