import { useState, useRef, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler, FieldError } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import "./form.css";
import { TbEye, TbEyeClosed } from "react-icons/tb";
import GoogleLogin from "../googleLogin/GoogleLogin";
import { AuthService } from "../../services/authService";
import { UtilsService } from "../../services/utilsService";
import RecoveryPassword from "./modals/recoveryPassword/RecoveryPassword";
import { Divider } from "antd";

interface IFormData {
  email: string;
  password: string;
}

const Form = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const [rememberToken, setRememberToken] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [openRecoveryModal, setOpenRecoveryModal] = useState<boolean>(false);
  const [dataForm, setDataForm] = useState<IFormData>({
    email: "",
    password: "",
  });

  const handleOpenModal = () => {
    setOpenRecoveryModal(true);
  };

  const handleRememberToken = (e: ChangeEvent<HTMLInputElement>) => {
    setRememberToken(e.target.checked);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleGoogleLoginClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
  };

  const validationSchema = z.object({
    email: z
      .string()
      .max(60, { message: t("generics.forms.login_form_email_max_character") })
      .email({ message: t("generics.forms.login_form_valid_email") }),
    password: z
      .string()
      .min(1, { message: t("generics.forms.isRequired") })
      .min(6, { message: t("generics.forms.login_form_min_character") }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormData>({
    resolver: zodResolver(validationSchema),
  });

  const onChangePost = (field: keyof IFormData, value: string) => {
    setDataForm((prevDataForm) => ({
      ...prevDataForm,
      [field]: value,
    }));
  };

  const onSubmit: SubmitHandler<IFormData> = async (formData) => {
    try {
      const response = await AuthService.signIn(
        formData.email,
        formData.password,
        rememberToken
      );
      if (formRef.current) {
        formRef.current.reset();
        UtilsService.showSuccessToast(
          t("generics.forms.login_access_success", {
            firstName:
              AuthService.getUser().user_metadata.full_name.split(" ")[0],
          })
        );
      }
      navigate("/");
    } catch (err: any) {
      UtilsService.showErrorToast(t("generics.forms.wrong_login_data"));
      console.error(err);
    }
  };

  return (
    <div className="form-section">
      <h2 className="form-title">{t("generics.forms.login_form_title")}</h2>
      <p className="form-sub-title">
        {t("generics.forms.login_form_sub_title")}
      </p>
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)} ref={formRef} className="form">
        <div className="formControl">
          <label htmlFor="email">{t("generics.forms.login_form_email")}</label>
          <input
            id="email"
            type="email"
            {...register("email")}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onChangePost("email", e.target.value)
            }
            placeholder={t("generics.forms.login_form_email_suggestion")}
          />
          {errors.email && (
            <small className="error">
              {(errors.email as FieldError).message}
            </small>
          )}
        </div>

        <div className="formControl">
          <label htmlFor="password">
            {t("generics.forms.login_form_password")}
          </label>
          <div className="password-input-wrapper">
            <input
              id="password"
              type={showPassword ? "text" : "password"}
              {...register("password")}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChangePost("password", e.target.value)
              }
              placeholder={t("generics.forms.login_form_password_suggestion")}
            />
            <span
              className="password-toggle-btn"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <TbEye /> : <TbEyeClosed />}
            </span>
          </div>
          {errors.password && (
            <small className="error">
              {(errors.password as FieldError).message}
            </small>
          )}
          <div className="form-options">
            <span>
              <input
                type="checkbox"
                className="remember-check"
                checked={rememberToken}
                onChange={handleRememberToken}
              />
              <p className="remember-text">{t("generics.forms.remember_me")}</p>
            </span>
            <span className="forget-span" onClick={handleOpenModal}>
              <p className="forget">{t("generics.forms.forget_password")}</p>
            </span>
          </div>
        </div>
        <div className="buttons-container">
          <button type="submit" className="btn">
            {t("generics.forms.login_form_title")}
          </button>
          <GoogleLogin
            text={t("generics.google_login.login_title")}
            onClick={handleGoogleLoginClick}
          />
        </div>
      </form>
      <RecoveryPassword
        isOpen={openRecoveryModal}
        onClose={() => setOpenRecoveryModal(false)}
        onSendEmail={dataForm.email}
      ></RecoveryPassword>
    </div>
  );
};

export default Form;
