import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AuthService } from "../services/authService";
import { AppRoutes } from "./routes";

const PrivateRoute: React.FC = () => {
  const isAuthenticated = AuthService.getUser();
  return isAuthenticated ? <Outlet /> : <Navigate to={`/${AppRoutes.SIGN_IN}`} />;
};

export default PrivateRoute;
