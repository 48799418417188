import { Button, ConfigProvider, Form, Input, Modal } from "antd";
import { ISubject } from "../../../../interfaces/subject";
import { SubjectService } from "../../../../services/subjectService";
import { useTranslation } from "react-i18next";
import "./createSubject.css";
import { UtilsService } from "../../../../services/utilsService";

interface ISubjectForm {
  name: string;
}

const CreateSubjectModal: React.FC<any> = (props: {
  isOpen: boolean;
  onClose: any;
  subject?: ISubject;
  onCreate: any;
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const onCreate = async (values: ISubjectForm) => {
    try {
      if (props.subject) {
        await SubjectService.update(props.subject.id, values.name);
        UtilsService.showSuccessToast(t("modals.edit.subject_editing_success"));
      } else {
        await SubjectService.create(values.name);
        UtilsService.showSuccessToast(
          t("modals.create.subject_creating_success")
        );
      }
      form.setFieldValue("name", "");
      props.onCreate();
      props.onClose();
    } catch (e: any) {
      console.error(e);
      UtilsService.showErrorToast(e.message);
    }
  };

  return (
    <Modal
      open={props.isOpen}
      title={
        props.subject
          ? t("modals.create.subject_title_edit")
          : t("modals.create.subject_title")
      }
      onCancel={props.onClose}
      footer={null}
      className="create-subject-modal"
    >
      {/* Input per il nome del nuovo soggetto */}
      <ConfigProvider>
        <Form
          form={form}
          name="control-hooks"
          size="large"
          onFinish={onCreate}
          style={{
            maxWidth: 600,
          }}
        >
          <Form.Item
            name="name"
            label={t("modals.create.subject_name")}
            rules={[{ required: true }]}
            initialValue={props.subject?.name}
            className="create-subject-form-input"
          >
            <Input
              maxLength={50}
              minLength={6}
              placeholder={t("modals.create.name_suggestion")}
              className="create-subject-input"
            />
          </Form.Item>
          <Form.Item>
            <Button
              key="create"
              type="primary"
              htmlType="submit"
              className="create-subject-btn"
            >
              {props.subject ? t("common.edit") : t("common.create")}
            </Button>
          </Form.Item>
        </Form>
      </ConfigProvider>
    </Modal>
  );
};

export default CreateSubjectModal;
