import React, { ReactNode } from "react";
import "./authLayout.css";
import Logo from "../logo/logo";

interface AuthLayoutProps {
  leftSide: ReactNode;
  rightSide: ReactNode;
  className?: string;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({
  leftSide,
  rightSide,
  className,
}) => {
  const layoutClasses = "auth-layout" + (className ? ` ${className}` : "");
  return (
    <div className={layoutClasses}>
      <div className="logo">
        <Logo />
      </div>
      <div className="left-side">{leftSide}</div>
      <div className="right-side">{rightSide}</div>
    </div>
  );
};

export default AuthLayout;
