import { useState, useEffect } from "react";
import "./signUp.css";
import SignUpForm from "../../components/signUpForm/SignUpForm";
import { Link, useNavigate } from "react-router-dom";
import { t } from "i18next";
import { AuthService } from "../../services/authService";
import AuthLayout from "../../components/authLayout/AuthLayout";
import { AppRoutes } from "../../routes/routes";
import { FaCirclePlay } from "react-icons/fa6";
import DashboardImg from "../../assets/images/dashboard.png";

const SignUp = () => {
  const navigate = useNavigate();
  const videoID = "Tnq41M96fi4";
  const imageBackground = DashboardImg;
  const [popupVisible, setPopupVisible] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isLaptop, setIsLaptop] = useState<boolean>(false);

  const playVideo = () => {
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
  };

  useEffect(() => {
    document.title = `PassMate | ${t("generics.phrases.sign_up")}`;
    if (AuthService.getUser()) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    const handleResizeMobile = () => {
      setIsMobile(window.innerWidth <= 430);
    };
    handleResizeMobile();
    window.addEventListener("resize", handleResizeMobile);
    return () => window.removeEventListener("resize", handleResizeMobile);
  }, []);

  useEffect(() => {
    const handleResizeLaptop = () => {
      setIsLaptop(window.innerWidth === 1024);
    };
    handleResizeLaptop();
    window.addEventListener("resize", handleResizeLaptop);
    return () => window.removeEventListener("resize", handleResizeLaptop);
  }, []);

  return (
    <AuthLayout
      leftSide={
        <div className="login-form-container">
          <SignUpForm />
          <Link to={`/${AppRoutes.SIGN_IN}`} className="link-registration">
            {t("pages.sign_up.link_to_sign_in")}
          </Link>
        </div>
      }
      rightSide={
        <div className="signUp-right-side">
          <div className="title-tutorial">
            <h2>{t("pages.sign_up.tutorial")}</h2>
          </div>
          <div
            className="video-player-overlay"
            style={{
              background: `url(${imageBackground})`,
              backgroundSize: isMobile || isLaptop ? "cover" : "unset",
            }}
          >
            <div className="play-btn" onClick={playVideo}>
              <FaCirclePlay />
            </div>
          </div>
          {popupVisible && (
            <div className="popup-container" onClick={closePopup}>
              <div className="popup-content">
                <iframe
                  width="560"
                  height="315"
                  src={`https://www.youtube.com/embed/${videoID}`}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          )}
        </div>
      }
    />
  );
};

export default SignUp;
