export class Constants {
    // Style
    static COLOR_PRIMARY = '#8C52FF';
    static COLOR_PRIMARY_ALPHA = '#8C52FF11';
    static COLOR_SECONDARY = '#FBEAFF';

    // Config
    static MAX_ANSWER_POINTS = 100;
    static AVAILABLE_LANGUAGES = ['it', 'en'];
    static AVAILABLE_SCHOOL_TYPES = ['primary_school', 'secondary_school', 'high_school', 'university', 'post_university', 'master'];
}