import { Button, ConfigProvider, Form, Input, Modal } from "antd";
import { useTranslation } from "react-i18next";
import "./recoveryPassword.css";
import { UtilsService } from "../../../../services/utilsService";
import { AuthService } from "../../../../services/authService";
import { useEffect, useState } from "react";

const tailLayout = {
  wrapperCol: { offset: 7, span: 17 },
};

const RecoveryPassword: React.FC<any> = (props: {
  isOpen: boolean;
  onClose: any;
  onSendEmail: any;
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSendEmail = async () => {
    setIsLoading(true);
    try {
      const values = await form.validateFields();
      await AuthService.sendResetPasswordRequest(values.email);
      UtilsService.showSuccessToast(t('generics.forms.reset_password', { email: values.email }), true);
      setIsLoading(false);
      props.onClose();
    } catch (error) {
    }
  };

  return (
    <Modal
      centered
      open={props.isOpen}
      title={t("modals.recovery.title")}
      onCancel={props.onClose}
      footer={null}
      className="recovery-modal"
    >
      <ConfigProvider>
        <Form
          disabled={isLoading}
          form={form}
          onFinish={onSendEmail}
          size="large"
          style={{
            maxWidth: 600,
          }}
        >
          <Form.Item
            name="email"
            label={t("modals.recovery.email")}
            rules={[{ required: true }]}
          >
            <Input
              type="email"
              maxLength={50}
              minLength={6}
              placeholder={t("modals.recovery.suggestion")}
              className="recovery-input"
            />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button
              loading={isLoading}
              key="create"
              htmlType="submit"
              className="recovery-btn"
            >
              {t("modals.recovery.button")}
            </Button>
          </Form.Item>
        </Form>
      </ConfigProvider>
    </Modal>
  );
};

export default RecoveryPassword;
