import { supabase } from './supabaseClient';
import { AuthService } from './authService';
import { IExam } from '../interfaces/exam';
import { UtilsService } from './utilsService';
import { ISubject } from '../interfaces/subject';
import { t } from 'i18next';

export class SubjectService {
    static async create(name: string): Promise<void> {
        const user = AuthService.getUser();
        const { error } = await supabase.from('subjects').insert({ name, user: user.id });
        if (error) {
            UtilsService.showErrorToast(error.message);
            throw error;
        }
    }

    static async update(id: number, name: string): Promise<void> {
        const { error } = await supabase.from('subjects').update({ name }).eq('id', id);
        if (error) {
            UtilsService.showErrorToast(error.message);
            throw error;
        }
    }

    static async delete(id: number): Promise<void> {
        const { error } = await supabase.from('subjects').delete().eq('id', id);
        if (error) {
            if (error.code === '23503') {
                UtilsService.showErrorToast(t('generics.errors.exams_connected'));
            } else {
                UtilsService.showErrorToast(error.message);
            }
            throw error;
        }
    }

    static async getAll(): Promise<ISubject[]> {
        const user = AuthService.getUser();
        const response = await supabase.from('subjects').select('*').eq('user', user.id);
        if (response.error) {
            UtilsService.showErrorToast(response.error.message);
            throw response.error;
        } else {
            return response.data;
        }
    }

    static async get(id: number): Promise<IExam> {
        const response = await supabase.from('subjects').select('*').eq('id', id).limit(1).single();
        if (response.error) {
            UtilsService.showErrorToast(response.error.message);
            throw response.error;
        } else {
            return response.data;
        }
    }
}