import { useState, useRef, ChangeEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm, SubmitHandler, FieldError } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import './resetForm.css'
import { AuthService } from "../../services/authService";
import { UtilsService } from "../../services/utilsService";

interface IFormData {
  password: string;
  confirm_password: string;
}

const ResetForm = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [resetUrl, setResetUrl] = useState<string>('');
  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement>(null);
  const [dataForm, setDataForm] = useState<IFormData>({
    password: "",
    confirm_password: "",
  });

  const validationSchema = z.object({
    password: z
      .string()
      .min(8, { message: t("generics.forms.login_form_min_character") })
      .max(14, { message: t("generics.forms.login_form_max_character") })
      .refine(
        (password) => {
          const regex =
            /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,14}$/;
          return regex.test(password);
        },
        { message: t("generics.forms.login_form_valid_password") }
      ),
    confirm_password: z
      .string()
      .min(8, { message: t("generics.forms.login_form_min_character") })
      .max(14, { message: t("generics.forms.login_form_max_character") })
      .refine(
        (password) => {
          const regex =
            /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,14}$/;
          return regex.test(password);
        },
        { message: t("generics.forms.login_form_valid_password") }
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormData>({
    resolver: zodResolver(validationSchema),
  });

  const onChangePost = (field: keyof IFormData, value: string) => {
    setDataForm((prevDataForm) => ({
      ...prevDataForm,
      [field]: value,
    }));
  };

  const onSubmit: SubmitHandler<IFormData> = async (formData) => {
    if (formData.confirm_password !== formData.password) {
      UtilsService.showErrorToast(t('generics.forms.password_match_error'));
      return;
    }
    try {
      await AuthService.resetPassword(formData.password);
      UtilsService.showSuccessToast(t('generics.forms.password_reset_success'));
      navigate('/');
    } catch (err) {}
  };

  useEffect(() => {
    if (searchParams.get('email')) setEmail(searchParams.get('email')!);
    if (searchParams.get('resetUrl')) setResetUrl(searchParams.get('resetUrl')!);
  }, []);

  return (
    <div className="reset-form-section">
      <div className="reset-form-head">
        <h2 className="reset-title">{t("generics.forms.reset_form_title")}</h2>
        <p className="text">{email}</p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} ref={formRef} className="reset-form">
        <div className="reset-formControl">
          <label htmlFor="password">
            {t("generics.forms.signUp_form_password")}
          </label>
          <input
            id="password"
            type="password"
            placeholder={t('generics.forms.signUp_password_suggestion')}
            {...register("password")}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onChangePost("password", e.target.value)
            }
          />
          {errors.password && (
            <small className="reset-error">
              {(errors.password as FieldError).message}
            </small>
          )}
        </div>

        <div className="reset-formControl">
          <label htmlFor="confirm_password">
            {t("generics.forms.signUp_form_confirmPassword")}
          </label>
          <input
            id="confirm_password"
            type="password"
            placeholder={t('generics.forms.signUp_password_suggestion')}
            {...register("confirm_password")}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onChangePost("confirm_password", e.target.value)
            }
          />
          {errors.confirm_password && (
            <small className="reset-error">
              {(errors.confirm_password as FieldError).message}
            </small>
          )}
        </div>
        <div className="btn-reset-container">
        <button type="submit" className="reset-btn">
          {t("common.reset")}
        </button>
        </div>
      </form>
    </div>
  );
};

export default ResetForm;
