import { Button, ConfigProvider, DatePicker, Divider, Form, Input, Modal, Radio, Select, Space } from "antd";
import { useEffect, useState } from "react";
import { Difficulty } from "../../../../enums/difficulty.enum";
import { ISubject } from "../../../../interfaces/subject";
import { ExamService } from "../../../../services/examService";
import dayjs from 'dayjs'
import { UtilsService } from "../../../../services/utilsService";
import itIT from 'antd/locale/it_IT';
import itITDayJs from 'dayjs/locale/it';
import i18next, { t } from "i18next";
import "./createExamModal.css";
import { IExam } from "../../../../interfaces/exam";
import { ISimulation } from "../../../../interfaces/simulation";

const { Option } = Select;

export const locales: { [key: string]: any } = {
    it: itIT,
    en: null
}

export const localesDayJs: { [key: string]: any } = {
    it: itITDayJs,
    en: null
}

dayjs.locale(localesDayJs[i18next.language]);

const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 20 },
};

interface IExamForm {
    name: string;
    subject: number;
    datetime: dayjs.Dayjs;
}

const CreateExamModal: React.FC<any> = (props: { isOpen: boolean, onClose: any, subjects: ISubject[], onCreate: any, exam?: IExam, onUpdate: any, simulations: ISimulation[] }) => {
    const [form] = Form.useForm();
    const [creating, setCreating] = useState(false);

    const onFinish = async (values: IExamForm) => {
        // Check past date
        if (values.datetime.isBefore(dayjs())) {
            UtilsService.showErrorToast(t('pages.dashboard.exams.past_date_error'));
            return;
        }
        setCreating(true);
        try {
            if (props.exam) {
                // Update
                const updatedExam = Object.assign({}, props.exam);
                updatedExam.datetime = values.datetime.toISOString();
                updatedExam.subject = props.subjects.find(item => item.id === values.subject)!;
                updatedExam.name = values.name;
                await ExamService.update(updatedExam);
                UtilsService.showSuccessToast(t('pages.dashboard.exams.edit_success'));
                props.onUpdate();
            } else {
                // Create
                await ExamService.create(values.name, values.datetime.toISOString(), values.subject);
                UtilsService.showSuccessToast(t('pages.dashboard.exams.create_success'));
                props.onCreate();
            }
        } catch (e: any) {
        }
        setCreating(false);
    };

    return <Modal title={props.exam ? t('pages.dashboard.exams.edit_exam') : t('pages.dashboard.exams.add_exam')} footer={null} open={props.isOpen} onCancel={props.onClose}>
        <div className="modal-content">
            <ConfigProvider locale={locales[i18next.language]}>
                <Form
                    {...layout}
                    form={form}
                    name="control-hooks"
                    size="large"
                    onFinish={onFinish}
                    style={{ maxWidth: 600 }}
                >
                    <Form.Item name="subject" label={t('pages.dashboard.exams.subject')} rules={[{ required: true }]} initialValue={props.exam?.subject?.id}>
                        <Select
                            placeholder={t('pages.dashboard.exams.select_a_subject')}
                            allowClear
                        >
                            {props.subjects.map((subject) => (
                                <Option value={subject.id} key={subject.id}>{subject.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="name" label={t('pages.dashboard.exams.exam_name')} rules={[{ required: true }]} initialValue={props.exam?.name}>
                        <Input maxLength={100} minLength={3} placeholder={t('pages.dashboard.exams.name_suggestion')} disabled={props.simulations && props.simulations.length > 0} />
                    </Form.Item>
                    <Form.Item label={t('pages.dashboard.exams.datetime')} name="datetime" rules={[{ required: true }]} initialValue={dayjs.utc(props.exam?.datetime).local()}>
                        <DatePicker showTime locale={locales[i18next.language]} format="YYYY-MM-DD HH:mm"
                        />
                    </Form.Item>
                    <Divider />
                    <Button type="primary" htmlType="submit" className="create-subject-btn">
                        {props.exam ? t('common.edit') : t('common.add')}
                    </Button>
                </Form>
            </ConfigProvider>
        </div>
    </Modal>
}

export default CreateExamModal;