import { UtilsService } from "./utilsService";
import { supabase } from './supabaseClient';
import { AuthService } from "./authService";
import { IPayment } from "../interfaces/payment";

export class PaymentService {

    static async getPlans() {
        const response = await supabase.from('plans').select('*');
        if (response.error) {
            UtilsService.showErrorToast(response.error.message);
            throw response.error;
        } else {
            return response.data;
        }
    }

    static async updateUserPlan() {
        return await supabase.functions.invoke('check-user-plans');
    }

    static async getAvailableSimulationsNumber(): Promise<number> {
        const user = AuthService.getUser();
        const { data, error } = await supabase.from('user_plan').select('simulations').eq('user', user.id).limit(1).single();
        if (error) {
            UtilsService.showErrorToast(error.message);
            throw error;
        } else {
            return data.simulations;
        }
    }

    static async getPaymentsHistory(): Promise<IPayment[]> {
        const user = AuthService.getUser();
        const { data, error } = await supabase.from('payments').select('*').eq('user_email', user.email);
        if (error) {
            UtilsService.showErrorToast(error.message);
            throw error;
        } else {
            return data;
        }
    }
}