import { Button, Modal } from "antd";
import { useState } from "react";
import { UtilsService } from "../../../../services/utilsService";
import "./deleteSubject.css";
import { ISubject } from "../../../../interfaces/subject";
import { SubjectService } from "../../../../services/subjectService";
import { useTranslation } from "react-i18next";

const DeleteSubjectModal: React.FC<any> = (props: {
  isOpen: boolean;
  onClose: any;
  onUpdate: any;
  subject: ISubject;
}) => {
  const { t } = useTranslation();
  const [isDeleting, setIsDeleting] = useState(false);

  const onDelete = async (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    subject: ISubject
  ) => {
    setIsDeleting(true);
    try {
      await SubjectService.delete(subject.id);
      UtilsService.showSuccessToast(t("modals.delete.subject_deleting_success"));
      props.onUpdate();
    } catch(e) {}
    setIsDeleting(false);
  };

  return (
    <Modal
      open={props.isOpen}
      title={t("modals.delete.subject_title")}
      onCancel={props.onClose}
      footer={[
        <Button onClick={props.onClose}>{t("common.return")}</Button>,
        <Button
          danger
          type="primary"
          loading={isDeleting}
          onClick={(event) => onDelete(event, props.subject)}
        >
          {t("common.delete")}
        </Button>,
      ]}
    >
      <p>
        {t("modals.delete.subject_confirm_delete", {
          subjectNme: props.subject?.name,
        })}
      </p>
    </Modal>
  );
};

export default DeleteSubjectModal;
