import { Button, Divider, Tag } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import "./planCard.css";
import { IPlan } from "../../interfaces/plan";
import { t } from "i18next";
import { AuthService } from "../../services/authService";
import getStripe from "../../services/getStripe";
import { PlanName } from "../../enums/planName.enum";

const PlanCard = (plan: IPlan) => {
  const user = AuthService.getUser();

  async function buyNow() {
    let bundlePrice = process.env.REACT_APP_SMALL_BUNDLE_ID;
    switch (plan.name) {
      case PlanName.NORMAL:
        bundlePrice = process.env.REACT_APP_NORMAL_BUNDLE_ID;
        break;
      case PlanName.BIG:
        bundlePrice = process.env.REACT_APP_BIG_BUNDLE_ID;
        break;
    }

    const stripe = await getStripe();
    const response = await stripe.redirectToCheckout({
      lineItems: [
        {
          price: bundlePrice,
          quantity: 1,
        },
      ],
      mode: "payment",
      successUrl: `${process.env.REACT_APP_SITE_URL}/settings?session_id={CHECKOUT_SESSION_ID}`,
      cancelUrl: `${process.env.REACT_APP_SITE_URL}/settings?failed_checkout=true`,
      customerEmail: user.email,
    });
  }

  return (
    <div className="plan-card-box">
      <div className="plan-card-content">
        <div className="plan-card-info-container">
          <div className="plan-card-info-titles">
            <h4 className="plan-card-title">
              {t(`generics.plans.titles.${plan.name}`)}
            </h4>
            <h6 className="plan-card-subtitle">
              {t(`generics.plans.descriptions.${plan.name}`)}
            </h6>
          </div>
          <Divider />
          <div className="plan-card-description-container">
            <ul>
              <li>
                <CheckOutlined className="plan-card-list-item-icon" />
                <p className="plan-card-list-item-text-important">
                  {plan.simulations} {t("generics.plans.simulations")}
                </p>
              </li>
              <li>
                <CheckOutlined className="plan-card-list-item-icon" />
                <p className="plan-card-list-item-text">
                  {t("generics.plans.answers_explanations")}
                </p>
              </li>
              <li>
                <CheckOutlined className="plan-card-list-item-icon" />
                <p className="plan-card-list-item-text">
                  {t("generics.plans.realistic_questions")}
                </p>
              </li>
              <li>
                <CheckOutlined className="plan-card-list-item-icon" />
                <p className="plan-card-list-item-text">
                  {t("generics.plans.ai_functionalities")}
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="plan-card-price-container">
          {plan.discount > 0 && (
            <Tag
              color={plan.discount > 10 ? "green" : "orange"}
              className="plan-card-discount"
            >
              {plan.discount}% {t('generics.plans.offer_text')}
            </Tag>
          )}
          <p className="plan-card-price">
            {plan.discount > 0
              ? (plan.price - (plan.price * plan.discount) / 100)
                  .toPrecision(4)
                  .toString()
                  .replace(".", ",")
              : plan.price.toString().replace(".", ",")}{" "}
            {t("generics.plans.price_currency")}
          </p>
          {plan.discount > 0 && (
            <p className="plan-card-price-old">
              {plan.price.toString().replace(".", ",")}
              {t("generics.plans.price_currency")}
            </p>
          )}
        </div>
      </div>
      <div className="plan-card-footer">
        <Button
          type="primary"
          size="large"
          className="plan-card-buy-button"
          onClick={buyNow}
        >
          {t('common.buy')}
        </Button>
      </div>
    </div>
  );
};

export default PlanCard;
