import { Bounce, toast } from "react-toastify";
import { Difficulty } from "../enums/difficulty.enum";
import { t } from "i18next";
import { QuestionsType } from "../enums/questionsType.enum";

export class UtilsService {
    static showErrorToast(message: string): void {
        toast.error(message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
        });
    }

    static showSuccessToast(message: string, important?: boolean): void {
        toast.success(message, {
            position: important ? "top-center" : "bottom-right",
            autoClose: important ? 8000 : 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
        });
    }

    static showInfoToast(message: string): void {
        toast.info(message, {
            position: "bottom-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Bounce,
        });
    }
    
    static getDifficultyStringFromEnum(difficulty: Difficulty): string {
        switch (difficulty) {
            case Difficulty.MEDIUM:
                return t('pages.dashboard.exams.medium');
            case Difficulty.HARD:
                return t('pages.dashboard.exams.hard');
            default:
                return t('pages.dashboard.exams.easy');
        }
    }

    static getQuestionsTypeStringFromEnum(type: QuestionsType): string {
        switch (type) {
            case QuestionsType.MULTIPLE:
                return t('pages.dashboard.exams.questions_type_multiple');
            default:
                return t('pages.dashboard.exams.questions_type_open');
        }
    }

    static getSchoolTypeString(schoolType: string): string {
        return(t('generics.school_types.' + schoolType));
    }
}