import React, { useState } from "react";
import "./subjectCard.css";
import { PiDotsThreeVerticalBold } from "react-icons/pi";
import { CiEdit, CiCircleRemove } from "react-icons/ci";
import { Button, Dropdown, Menu } from "antd";
import { useTranslation } from "react-i18next";
import { ISubject } from "../../interfaces/subject";
import DeleteSubjectModal from "../../pages/dashboard/modals/deleteSubject/DeleteSubject";
import CreateSubjectModal from "../../pages/dashboard/modals/createSubject/CreateSubjectModal";

const SubjectCard: React.FC<any> = (props: {
  subject: ISubject;
  onUpdate: any;
}) => {
  const { t } = useTranslation();
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalopen] = useState<boolean>(false);

  const onCloseModal = () => {
    setIsDeleteModalopen(false);
    props.onUpdate();
  };

  return (
    <div className="card">
      <div className="center-card">{props.subject?.name}</div>
      <Dropdown
        className="menu"
        overlay={
          <Menu>
            <Menu.Item key="1" onClick={() => setIsEditModalOpen(true)}>
              <div className="subject-menu-item"><CiEdit /> {t("common.edit")}</div>
            </Menu.Item>
            <Menu.Item key="2" onClick={() => setIsDeleteModalopen(true)}>
              <div className="subject-menu-item"><CiCircleRemove /> {t("common.delete")}</div>
            </Menu.Item>
          </Menu>
        }
        placement="bottomRight"
      >
        <Button className="menu-btn">
          <PiDotsThreeVerticalBold />
        </Button>
      </Dropdown>

      {/* Modale di modifica */}
      <CreateSubjectModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        onCreate={props.onUpdate}
        subject={props.subject}
      ></CreateSubjectModal>

      {/* Modale di eliminazione */}
      <DeleteSubjectModal
        isOpen={isDeleteModalOpen}
        onUpdate={onCloseModal}
        onClose={() => setIsDeleteModalopen(false)}
        subject={props.subject}
      ></DeleteSubjectModal>
    </div>
  );
};

export default SubjectCard;
