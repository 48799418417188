import { Button } from "antd";
import Lottie from "lottie-react";
import NotFoundLottie from "../../assets/json/DinoGoogleNotFound.json";
import "./notFound.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";

const NotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isTablet, setIsTablet] = useState<boolean>(false);

  useEffect(() => {
    const handleResizeMobile = () => {
      setIsMobile(window.innerWidth <= 430);
    };
    handleResizeMobile();
    window.addEventListener("resize", handleResizeMobile);
    return () => window.removeEventListener("resize", handleResizeMobile);
  }, []);

  useEffect(() => {
    const handleResizeTablet = () => {
      setIsTablet(window.innerWidth <= 825 && window.innerWidth >= 431);
    };
    handleResizeTablet();
    window.addEventListener("resize", handleResizeTablet);
    return () => window.removeEventListener("resize", handleResizeTablet);
  }, []);

  const goToDash = () => {
    navigate("/");
  };
  return (
    <div className="not-found-section">
      <div className="not-found-lottie">
        <Lottie
          animationData={NotFoundLottie}
          style={{
            width: isMobile ? "70%" : "30%",
            minWidth: isTablet ? "70%" : "auto",
          }}
        />
      </div>
      <div className="not-found-text">
        <h4 className="not-found-title">{t("pages.not_found.title")}</h4>
      </div>
      <div>
        <Button className="not-found-btn" onClick={goToDash}>
          {t("pages.not_found.button")}
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
