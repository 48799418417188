import React, { useEffect, useState } from "react";
import "./login.css";
import Lottie from "lottie-react";
import LoginLottie from "../../assets/json/loginLottie.json";
import Form from "../../components/form/Form";
import { Link, useNavigate } from "react-router-dom";
import { t } from "i18next";
import { AuthService } from "../../services/authService";
import AuthLayout from "../../components/authLayout/AuthLayout";
import { AppRoutes } from "../../routes/routes";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    document.title = `PassMate | ${t("generics.phrases.sign_in")}`;
    // Check if already logged
    if (AuthService.getUser()) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    const handleResizeMobile = () => {
      setIsMobile(window.innerWidth <= 430);
    };
    handleResizeMobile();
    window.addEventListener("resize", handleResizeMobile);
    return () => window.removeEventListener("resize", handleResizeMobile);
  }, []);

  return (
    <AuthLayout
      leftSide={
        <div className="login-form-container">
          <Form />
          <Link to={`/${AppRoutes.SIGN_UP}`} className="link-registration">
            {t("pages.login.link_to_registration")}
          </Link>
        </div>
      }
      rightSide={
        isMobile ? null : (
          <Lottie animationData={LoginLottie} style={{ width: "70%" }} />
        )
      }
    />
  );
};

export default Login;
