import { supabase } from './supabaseClient';
import { AuthService } from './authService';
import { UtilsService } from './utilsService';
import { ISimulation } from '../interfaces/simulation';
import { Difficulty } from '../enums/difficulty.enum';
import { ISimulationForm } from '../pages/dashboard/modals/createSimulation/createSimulationModal';
import i18next from 'i18next';
import { SupabaseService } from './supabaseService';
import { AnswerItem } from '../pages/simulation/simulationPage';
import { Constants } from '../constants';
import { QuestionsType } from '../enums/questionsType.enum';

export class SimulationService {
    static async generateQuestions(examName: string, subjectName: string, totalQuestions: number, type: QuestionsType): Promise<any> {
        let promptKey = 'api.exam_question_open';
        if (type === QuestionsType.MULTIPLE) {
            promptKey = 'api.exam_question_multiple';
        }
        const user = AuthService.getUser();
        try {
            return JSON.parse((await SupabaseService.openAiSend(i18next.t(promptKey, { topics: examName, subjectName, totalQuestions, schoolType: UtilsService.getSchoolTypeString(user.user_metadata.school_type) }), 'question')).data);
        } catch (e) {
            UtilsService.showErrorToast('Something went wrong');
        }
    }

    static async checkAnswers(examName: string, questions: string[], answers: string[], type: QuestionsType): Promise<AnswerItem[]> {
        let promptKey = 'api.answer_check_open';
        if (type === QuestionsType.MULTIPLE) {
            promptKey = 'api.answer_check_multiple';
        }
        const result: AnswerItem[] = [];
        for (let i = 0; i < questions.length; i++) {
            const response = JSON.parse((await SupabaseService.openAiSend(i18next.t(promptKey, { examName, question: questions[i], answer: answers[i], maxPoints: Constants.MAX_ANSWER_POINTS }), 'answer')).data);
            response.answer = answers[i];
            result.push(response);
        }
        return result;
    }
    
    static async create(simulation: ISimulationForm): Promise<ISimulation[]> {
        const body = {
            exam: simulation.examId,
            difficulty: simulation.difficulty,
            total_questions: simulation.totalQuestions,
            questions_json: simulation.questionsJson,
            type: simulation.type
        }
        const { error, data } = await supabase.from('simulations').insert(body).select();
        if (error) {
            UtilsService.showErrorToast(error.message);
            throw error;
        } else {
            return data;
        }
    }

    static async getAll(examId: number): Promise<ISimulation[]> {
        const user = AuthService.getUser();
        const response = await supabase.from('simulations').select('*, exam(*)').eq('exam', examId).eq('exam.user', user.id).order('created_at', { ascending: false });
        if (response.error) {
            UtilsService.showErrorToast(response.error.message);
            throw response.error;
        } else {
            return response.data;
        }
    }

    static async get(id: number): Promise<ISimulation> {
        const user = AuthService.getUser();
        const response = await supabase.from('simulations').select('*, exam!inner(*)').eq('id', id).eq('exam.user', user.id).limit(1).single();
        if (response.error) {
            console.error(response.error);
            throw response.error;
        } else {
            return response.data;
        }
    }

    static async delete(simulationIds: number[]): Promise<void> {
        const response = await supabase.from('simulations').delete().in('id', simulationIds)
        if (response.error) {
            UtilsService.showErrorToast(response.error.message);
            throw response.error;
        }
    }

    static async update(simulation: ISimulation): Promise<void> {
        const body: any = {...simulation};
        body.exam = simulation.exam.id;
        delete body.id;
        const { error } = await supabase.from('simulations').update(body).eq('id', simulation.id);
        if (error) {
            UtilsService.showErrorToast(error.message);
            throw error;
        }
    }
}